/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MilesNavbar from "components/Navbars/MilesNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";
import Carousel from "../IndexSections/Carousel.jsx";


class MMedia extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <MilesNavbar />
        <Carousel />
        <main ref="main">


          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/ac-phone.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                      <i className="ni ni-spaceship" />
                    </div>
                    <h3>Technology Innovation</h3>
                    <p>
                      From HR Technology for large corporations and VCs, to strategy development for new startups,
                      we strive to keep our finger pressed on the pulse of innovation. We invest our profits to allow us
                      to build knowledge in the areas of Artificial Intelligence, Virtual Reality and Realtime Big Data Analytics.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-atom" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Full Stack Development
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-laptop" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">MVP Testing & Market Due Diligence</h6>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 bg-gradient-white">
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-creative"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>



          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">"Let's innovate and disrupt the world"</h2>
                  <p className="lead text-white">

                  </p>
                </Col>
              </Row>
              
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Team MilesElectric</h2>
                  <p className="lead text-muted">
                    Great businesses are built by strong teams with resilience to change.
                    Our people are our biggest asset. Together we are on a journey to make a
                    difference and are passionate about the amazing things that we can
                    achieve. We champion our people and our customers. We innovate to
                    build legacies and do business like there is a tomorrow.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/chris.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Christopher</span>
                        <small className="h6 text-muted">CEO & Founder</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="https://twitter.com/ChrisRossRadio"
                          target="_blank"

                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.linkedin.com/in/chrisrossuk/"
                          target="_blank"

                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="https://www.crunchbase.com/person/christopher-ross-5"
                          target="_blank"

                        >
                          <i className="fa fa-angellist" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/lee.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Lee</span>
                        <small className="h6 text-muted">
                          Chief Technology & Systems Architect
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="mailto:lee@mileselectric.dev"
                          target="_blank"

                        >
                          <i className="fa fa-envelope" />
                        </Button>

                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/coral.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Coral</span>
                        <small className="h6 text-muted">Head of Media & Technology Development</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="danger"
                          href="https://twitter.com/coralroseradio"
                          target="_blank"

                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="danger"
                          href="https://www.linkedin.com/in/coralroseuk/"
                          target="_blank"

                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="danger"
                          href="mailto:coral@mileselectric.dev"
                          target="_blank"

                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/richard.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Richard</span>
                        <small className="h6 text-muted">Global Talent Officer, MilesElectric Consultancy</small>
                      </h5>
                      <div className="mt-3">

                      <Button
                        className="btn-icon-only rounded-circle"
                        color="info"
                        href="https://www.linkedin.com/in/richard-smith-miod-a823bb91/"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </Button>

                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="mailto:richard.smith@mileselectric-consultancy.com"
                          target="_blank"

                        >
                          <i className="fa fa-envelope" />
                        </Button>

                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/adam.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Adam</span>
                        <small className="h6 text-muted">Chief Operating Officer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="mailto:adam@mileselectric.dev"
                          target="_blank"

                        >
                          <i className="fa fa-envelope" />
                        </Button>

                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/katie.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Katie</span>
                        <small className="h6 text-muted">Business Development & Creative Talent Lead</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="luxury"
                          href="https://www.instagram.com/katieroyle/"
                          target="_blank"

                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="luxury"
                          href="mailto:katie@mileselectric.dev"
                          target="_blank"

                        >
                          <i className="fa fa-envelope" />
                        </Button>

                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/jamie.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Jamie</span>
                        <small className="h6 text-muted">Chief Creative Officer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="creative"
                          href="https://www.linkedin.com/in/jamie-royle-330332141/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </Button>

                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="creative"
                          href="mailto:jamie@mileselectric.dev"
                          target="_blank"
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>


        </main>
        <CardsFooter />
      </>
    );
  }
}

export default MMedia;
