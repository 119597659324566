/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class MilesNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/header.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/header.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav className="navbar-nav-hover align-items-lg-center" navbar>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-atom d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Technology</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="/mileselectric-media"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                            <img
                              alt="..."
                              style={{ height: 50, width: 50 }}
                              src={require("assets/img/brand/mm.png")}
                            />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              MilesElectric Media
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              "Let's innovate and disrupt the world"
                            </p>
                          </Media>
                        </Media>

                        <Media
                          className="d-flex align-items-center"
                          href="https://www.promisetrends.com"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/pt.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              PromiseTrends
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              A brand incubator for: Fashion loving trend setters.
                              Built by millennials. For all generations.
                            </p>
                          </Media>
                        </Media>



                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-glasses-2 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Fashion</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="https://www.instagram.com/promise512/"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/crowns.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Promise512
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Flagship PromiseTrends Brand ♕
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href="https://www.instagram.com/uswclothing/"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/usw.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              USW Clothing
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Manchester based clothing brand - All products hand printed/embroidered in the UK.
                            </p>
                          </Media>
                        </Media>

                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-tablet-button d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Digital</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">

                      <Media
                          className="d-flex align-items-center"
                          href="https://www.me-talent.com"
                          target="_blank"
                          >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/mc.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              ME Consultancy
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Utilizing extensive personal networks, we engage, attract and represent world-leading executive talent.
                            </p>
                          </Media>
                          </Media>

                        <Media
                          className="d-flex align-items-center"
                          href="https://www.air-cuts.com/"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/ac.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              AirCuts
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Digital platform connecting you with local stylists and mobile hairdressers we know and trust.
                            </p>
                          </Media>
                        </Media>

                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-spaceship d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Innovation</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href="https://twitter.com/healthzoos"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/hz.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              HealthZoos
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Helping Healthy Food become more convenient, affordable & fun.
                            </p>
                          </Media>
                        </Media>
                      
                        <Media
                          className="d-flex align-items-center"
                          href="https://www.promisetrends.com/crowns/"
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-neutral rounded-circle text-white">
                          <img
                            alt="..."
                            style={{ height: 50, width: 50 }}
                            src={require("assets/img/brand/gpt.png")}
                          />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-primary mb-md-1">
                              Promise Portal
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Connecting creative talent, models, photographers, studios in a convenient, safe and meaningful way.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>




                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/mileselectricmedia/"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Let's get visual, visual
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/MilesElectricTM"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      "Opinions are probably our own"
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.linkedin.com/company/mileselectric-media/"
                      id="tooltip356693512"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        LinkedIn
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693512">
                      Ok, let's talk Business.
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default MilesNavbar;
